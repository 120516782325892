@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "UbuntuRegular";
  src:local("UbuntuRegular"), url("./fonts/Ubuntu-Regular.ttf") format("truetype") ;
}
@font-face {
  font-family: "UbuntuSemiBold";
  src:local("UbuntuSemiBold"), url("./fonts/Ubuntu-Medium.ttf") format("truetype") ;
}
@font-face {
  font-family: "UbuntuBold";
  src: local("UbuntuBold"),
   url("./fonts/Ubuntu-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "UbuntuLight";
  src:local("UbuntuLight"), url("./fonts/Ubuntu-Light.ttf") format("truetype") ;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  /* inset: 0px !important; */
}

.card {
  box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),
    0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),
    0 0 16px hsl(0deg 0% 0% / 0.075);
  transform-origin: 10% 60%;
}

.splash {
  clip-path: path(
    'M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z'
  );
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
